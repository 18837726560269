export default {
  100: '#F5F5F5',
  200: '#EFEFEF',
  300: '#E8E8E8',
  400: '#D9D9D9',
  50 : '#fafafa',
  500: '#BFBFBF',
  600: '#8C8C8C',
  700: '#595959',
  800: '#262626',
  A14: '#BFBFBF40',
  A40: '#26262640'
}
