export default {
  contrastText: '#262626',
  green       : {
    10 : '#DBFFEB',
    500: '#66C491'
  },
  red: {
    10 : '#FFEBED',
    500: '#ED2B2B'
  },
  yellow: {
    10 : '#FFF2C2',
    500: '#FDC026'
  }
}

