export default {
  10          : '#F2F4F7',
  100         : '#CCD4E0',
  200         : '#99A9C2',
  300         : '#667DA3',
  400         : '#335285',
  50          : '#E6E9F0',
  500         : '#002766',
  600         : '#00235C',
  700         : '#001F52',
  800         : '#001B47',
  900         : '#00173D',
  A14         : '#00276614',
  F           : '#F7F8FA',
  contrastText: '#fff',
  dark        : '#00235C',
  light       : '#335285',
  main        : '#002766'
}
