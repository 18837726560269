import createTheme from '@material-ui/core/styles/createTheme'
import { bluePrimary, blueSecondary, status, krowdy, grey, amber, green, alt, custom, darkAlt } from '../colors'
import shadows, { customShadows } from './shadows'

const theme = createTheme()

export default {
  customShadows,
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'initial'
      }
    },

    MuiChip: {
      colorPrimary: {
        backgroundColor: bluePrimary[10],
        borderColor    : bluePrimary[500],
        color          : grey[700]
      },
      outlinedPrimary: {
        backgroundColor: bluePrimary[10],
        borderColor    : bluePrimary[500],
        color          : grey[700]
      },
      root: {
        background: blueSecondary[10],
        color     : grey[700],
        padding   : theme.spacing(1.125, 1.5)
      },
      sizeSmall: {
        height : 24,
        padding: theme.spacing(0.75, 1.5)
      }
    },

    MuiFormHelperText: {
      contained: {
        marginLeft: theme.spacing(1)
      }
    },

    MuiFormLabel: {
      root: {
        color : grey[600],
        zIndex: 1
      }
    },

    MuiInput: {
      // input: {
      //   fontSize: theme.typography.pxToRem(14)
      // },
      underline: {
        '&:before': {
          borderBottomColor: blueSecondary[50]
        },
        '&:hover&:before': {
          borderBottomColor: grey[600]
        }
      }
    },

    MuiInputAdornment: {
      positionEnd: {
        marginLeft: '5px'
      },
      positionStart: {
        marginRight: '5px'
      }
    },

    MuiInputBase: {
      root: {
        color: grey[800]
      }
    },

    MuiInputLabel: {
      formControl: {
        transform: 'translate(8px, 12px) scale(1)'
      },
      outlined: {
        '&$shrink': {
          transform: 'translate(8px, -6px) scale(0.9)'
        },
        transform: 'translate(14px, 10px) scale(1)'
      },
      shrink: {
        transform: 'translate(0px, -6px) scale(0.9)'
      }
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: theme.spacing(1)
      },

      adornedStart: {
        paddingLeft: theme.spacing(1)
      },
      input: {
        boxSizing: 'border-box',
        height   : '32px',
        padding  : theme.spacing(0.75, 1)
      },
      inputMarginDense: {
        paddingLeft: theme.spacing(1)
      },
      notchedOutline: {
        borderColor: blueSecondary[50],
        padding    : theme.spacing(0, 0.375)
      },
      root: {
        // '&$focused $notchedOutline': {
        //   borderColor: 'purple'
        // },
        '&:hover $notchedOutline': {
          borderColor: grey[600]
        }
      }
    },
    MuiPaper: {
      outlined: {
        borderColor: blueSecondary[50]
      },
      rounded: {
        borderRadius: 8
      }
    },
    MuiSelect: {
      icon: {
        top      : '50%',
        transform: 'translateY(-50%)'
      },
      iconOutlined: {
        top      : '50%',
        transform: 'translateY(-50%)'
      }
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '1.125rem'
      },
      root: {
        fontSize: theme.typography.pxToRem(18)
      }
    },
    MuiSwitch: {
      switchBase: {
        color: grey[400]
      }
    },
    MuiTooltip: {
      // tooltip: {
      //   alignItems    : 'center',
      //   borderRadius  : theme.spacing(0.5),
      //   display       : 'flex',
      //   minHeight        : 30,
      //   justifyContent: 'center',
      //   minWidth      : 46
      // }
    }
    // MuiTextField: {
    //   root: {
    //     '& .MuiInputLabel-outlined': {
    //       transform: 'translate(8px, 10px) scale(1)'
    //     },
    //     '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    //       transform: 'translate(8px, -5px) scale(0.75)'
    //     }
    //   }
    // }
  },
  palette: {
    alt,
    amber,
    custom,
    darkAlt,
    green,
    grey,
    krowdy,
    primary  : bluePrimary,
    secondary: blueSecondary,
    status
  },
  shadow: {
    krowdy   : `0 0 8px ${krowdy[500]}`,
    primary  : `0 0 8px ${bluePrimary[500]}`,
    secondary: `0 0 8px ${blueSecondary[500]}`
  },
  shadows,
  typography: {
    body1: {
      fontSize     : theme.typography.pxToRem(12),
      letterSpacing: 'normal',
      lineHeight   : '18px'
    },
    body2: {
      fontSize     : theme.typography.pxToRem(14),
      letterSpacing: 'normal',
      lineHeight   : '20px'
    },
    body3: {
      ...theme.typography.body1,
      fontSize     : theme.typography.pxToRem(16),
      letterSpacing: 'normal',
      lineHeight   : '22px'
    },
    caption1: {
      fontSize     : theme.typography.pxToRem(12),
      letterSpacing: 'normal',
      lineHeight   : '18px'
    },
    caption2: {
      ...theme.typography.caption,
      fontSize     : theme.typography.pxToRem(10),
      letterSpacing: 'normal',
      lineHeight   : '16px'
    },
    display1: {
      ...theme.typography.h1,
      fontFamily   : '"Trebuchet MS", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize     : theme.typography.pxToRem(53),
      fontWeight   : 700,
      letterSpacing: 'normal',
      lineHeight   : '115%'
    },
    display2: {
      ...theme.typography.h1,
      fontFamily   : '"Trebuchet MS", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize     : theme.typography.pxToRem(53),
      fontWeight   : 400,
      letterSpacing: 'normal',
      lineHeight   : '111%'
    },
    h1: {
      fontSize     : theme.typography.pxToRem(44),
      fontWeight   : 700,
      letterSpacing: 'normal',
      lineHeight   : theme.typography.pxToRem(50)
    },
    h2: {
      fontSize     : theme.typography.pxToRem(36),
      fontWeight   : 700,
      letterSpacing: 'normal',
      lineHeight   : theme.typography.pxToRem(42)
    },
    h3: {
      fontSize     : theme.typography.pxToRem(29),
      fontWeight   : 700,
      letterSpacing: 'normal',
      lineHeight   : theme.typography.pxToRem(35)
    },
    h4: {
      fontSize     : theme.typography.pxToRem(23),
      fontWeight   : 700,
      letterSpacing: 'normal',
      lineHeight   : theme.typography.pxToRem(29)
    },
    h5: {
      fontSize     : theme.typography.pxToRem(16),
      fontWeight   : 700,
      letterSpacing: 'normal',
      lineHeight   : theme.typography.pxToRem(22)
    },
    h6: {
      fontSize     : `${14 / 16}rem`,
      fontWeight   : 700,
      letterSpacing: 'normal',
      lineHeight   : theme.typography.pxToRem(20)
    },
    info1: {
      ...theme.typography.body1,
      fontSize     : theme.typography.pxToRem(12),
      fontStyle    : 'italic',
      letterSpacing: 'normal',
      lineHeight   : '18px'
    },
    info2: {
      ...theme.typography.body1,
      fontSize     : theme.typography.pxToRem(14),
      fontStyle    : 'italic',
      letterSpacing: 'normal',
      lineHeight   : '20px'
    }
  }
}
