export default {
  1 : '#DA9000',
  10: '#909090',
  2 : '#35B12B',
  3 : '#D92848',
  4 : '#734EA2',
  5 : '#3076C0',
  6 : '#D75A14',
  7 : '#007474',
  8 : '#A16540',
  9 : '#E561CF'
}
