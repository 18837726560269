import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, withStyles } from '../styles'
import { alpha } from '../styles/colorManipulator'
import { ButtonBase, CircularProgress } from '@material-ui/core'
import capitalize from '../utils/capitalize'
import { fade } from '../styles/colorManipulator'

export const styles = (theme) => ({
  buttonProgress: {
    left      : '50%',
    marginLeft: -12,
    marginTop : -4,
    position  : 'absolute',
    top       : '50%'
  },

  /* Styles applied to the root element if `color="inherit"`. */
  colorInherit: {
    borderColor: 'currentColor',
    color      : 'inherit'
  },
  /* Styles applied to the root element if `variant="contained"`. */
  contained: {
    '&$disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      boxShadow      : theme.shadows[0],
      color          : theme.palette.action.disabled
    },
    '&$focusVisible': {
      boxShadow: theme.shadows[6]
    },
    '&:active': {
      boxShadow: theme.shadows[8]
    },
    '&:hover': {
      '&$disabled': {
        backgroundColor: theme.palette.action.disabledBackground
      },
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        // backgroundColor: theme.palette.grey[300],
        boxShadow: theme.shadows[2]
      },
      backgroundColor: theme.palette.grey.A100,
      boxShadow      : 'none'
    },
    backgroundColor: theme.palette.grey[300],
    border         : '1px solid transparent',
    boxShadow      : 'none',
    color          : theme.palette.getContrastText(theme.palette.grey[300])
  },
  containedError: {
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: theme.palette.error.main
      },
      // Reset on touch devices, it doesn't add specificity
      backgroundColor: theme.palette.error.light
    },
    backgroundColor: theme.palette.error.main,
    color          : theme.palette.error.contrastText
  },

  containedKrowdy: {
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: theme.palette.krowdy.main
      },
      // Reset on touch devices, it doesn't add specificity
      backgroundColor: theme.palette.krowdy.light
    },
    backgroundColor: theme.palette.krowdy.main,
    color          : theme.palette.krowdy.contrastText
  },

  /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
  containedPrimary: {
    '&:hover': {
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main
      },

      backgroundColor: theme.palette.primary.light
    },
    backgroundColor: theme.palette.primary.main,
    color          : theme.palette.primary.contrastText
  },

  /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
  containedSecondary: {
    '&:hover': {
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.secondary.main
      },
      backgroundColor: theme.palette.secondary.light
    },
    backgroundColor: theme.palette.secondary.main,
    color          : theme.palette.secondary.contrastText
  },

  /* Styles applied to the root element if `size="large"` and `variant="contained"`. */
  containedSizeLarge: {
    fontSize: theme.typography.pxToRem(14)
  },

  /* Styles applied to the root element if `size="small"` and `variant="contained"`. */
  containedSizeSmall: {
    fontSize     : theme.typography.pxToRem(12),
    paddingBottom: theme.spacing(1),
    paddingTop   : theme.spacing(1)
  },

  /* Styles applied to the root element if `variant="dashed"`. */
  dashed: {
    '&$disabled': {
      border: `1px dashed ${theme.palette.action.disabledBackground}`
    },
    border: `1px dashed ${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}`
    // padding: '5px 15px'
  },

  /* Styles applied to the root element if `variant="dashed"` and `color="error"`. */
  dashedError: {
    '&$disabled': {
      border: `1px dashed ${theme.palette.action.disabled}`
    },
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      border         : `1px dashed ${theme.palette.error.main}`
    },
    border: `1px dashed ${fade(theme.palette.error.main, 0.5)}`,
    color : theme.palette.error.main
  },

  /* Styles applied to the root element if `variant="dashed"` and `color="krowdy"`. */
  dashedKrowdy: {
    '&$disabled': {
      border: `1px dashed ${theme.palette.action.disabled}`
    },
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: fade(theme.palette.krowdy.main, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      border         : `1px dashed ${theme.palette.krowdy.main}`
    },
    border: `1px dashed ${fade(theme.palette.krowdy.main, 0.5)}`,
    color : theme.palette.krowdy.main
  },

  /* Styles applied to the root element if `variant="dashed"` and `color="primary"`. */
  dashedPrimary: {
    '&:hover': {
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
      border         : `1px dashed ${theme.palette.primary.main}`
    },
    border: `1px dashed ${alpha(theme.palette.primary.main, 0.5)}`,
    color : theme.palette.primary.main
  },

  /* Styles applied to the root element if `variant="dashed"` and `color="secondary"`. */
  dashedSecondary: {
    '&$disabled': {
      border: `1px dashed ${theme.palette.action.disabled}`
    },
    '&:hover': {
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
      border         : `1px dashed ${theme.palette.secondary.main}`
    },
    border: `1px dashed ${alpha(theme.palette.secondary.main, 0.5)}`,
    color : theme.palette.secondary.main
  },

  /* Styles applied to the root element if `size="large"` and `variant="dashed"`. */
  dashedSizeLarge: {
    fontSize: theme.typography.pxToRem(14)
  },

  /* Styles applied to the root element if `size="small"` and `variant="dashed"`. */
  dashedSizeSmall: {
    fontSize: theme.typography.pxToRem(12)
  },

  /* Styles applied to the root element if `disableElevation={true}`. */
  disableElevation: {
    '&$disabled': {
      boxShadow: 'none'
    },
    '&$focusVisible': {
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:hover': {
      boxShadow: 'none'
    },
    boxShadow: 'none'
  },

  /* Pseudo-class applied to the root element if `disabled={true}`. */
  disabled: {},

  /* Styles applied to the endIcon element if supplied. */
  endIcon: {
    '&$iconSizeSmall': {
      marginRight: -2
    },
    display    : 'inherit',
    marginLeft : 8,
    marginRight: -4
  },

  /* Pseudo-class applied to the ButtonBase root element if the button is keyboard focused. */
  focusVisible: {},

  /* Styles applied to the root element if `fullWidth={true}`. */
  fullWidth: {
    width: '100%'
  },

  /* Styles applied to the icon element if supplied and `size="large"`. */
  iconSizeLarge: {
    '& > *:first-child': {
      fontSize: 18
    }
  },

  /* Styles applied to the icon element if supplied and `size="medium"`. */
  iconSizeMedium: {
    '& > *:first-child': {
      fontSize: 18
    }
  },

  /* Styles applied to the icon element if supplied and `size="small"`. */
  iconSizeSmall: {
    '& > *:first-child': {
      fontSize: 18
    }
  },

  /* Styles applied to the span element that wraps the children. */
  label: {
    ...theme.typography.body1,
    alignItems    : 'inherit',
    // Ensure the correct width for iOS Safari
    display       : 'inherit',
    justifyContent: 'inherit',
    width         : '100%'
  },

  /* Styles applied to the root element if `variant="outlined"`. */
  outlined: {
    '&$disabled': {
      border: `1px solid ${theme.palette.action.disabledBackground}`
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity)
    },
    border: `1px solid ${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}`
    // padding: '5px 15px'
  },

  /* Styles applied to the root element if `variant="outlined"` and `color="error"`. */
  outlinedError: {
    '&$disabled': {
      border: `1px solid ${theme.palette.action.disabled}`
    },
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      border         : `1px solid ${theme.palette.error.main}`
    },
    border: `1px solid ${fade(theme.palette.error.main, 0.5)}`,
    color : theme.palette.error.main
  },

  /* Styles applied to the root element if `variant="outlined"` and `color="krowdy"`. */
  outlinedKrowdy: {
    '&$disabled': {
      border: `1px solid ${theme.palette.action.disabled}`
    },
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: fade(theme.palette.krowdy.main, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      border         : `1px solid ${theme.palette.krowdy.main}`
    },
    border: `1px solid ${fade(theme.palette.krowdy.main, 0.5)}`,
    color : theme.palette.krowdy.main
  },

  /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
  outlinedPrimary: {
    '&:hover': {
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
      border         : `1px solid ${theme.palette.primary.main}`
    },
    border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    color : theme.palette.primary.main
  },

  /* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
  outlinedSecondary: {
    '&$disabled': {
      border: `1px solid ${theme.palette.action.disabled}`
    },
    '&:hover': {
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
      border         : `1px solid ${theme.palette.secondary.main}`
    },
    border: `1px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
    color : theme.palette.secondary.main
  },

  /* Styles applied to the root element if `size="large"` and `variant="outlined"`. */
  outlinedSizeLarge: {
    fontSize: theme.typography.pxToRem(14)
  },

  /* Styles applied to the root element if `size="small"` and `variant="outlined"`. */
  outlinedSizeSmall: {
    fontSize: theme.typography.pxToRem(12)
  },

  /* Styles applied to the root element. */
  root: {
    // ...theme.typography.button,
    '&$disabled': {
      color: theme.palette.action.disabled
    },
    '&:hover': {
      '&$disabled': {
        backgroundColor: 'transparent'
      },
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      // backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity),
      textDecoration: 'none'
    },
    borderRadius : theme.shape.borderRadius,
    boxSizing    : 'border-box',
    // color       : theme.palette.text.primary,
    minWidth     : 64,
    paddingBottom: theme.spacing(0.875),
    paddingTop   : theme.spacing(0.875),
    // padding     : '6px 16px',
    transition   : theme.transitions.create([ 'background-color', 'box-shadow', 'border' ], {
      duration: theme.transitions.duration.short
    })
  },

  /* Styles applied to the root element if `size="large"`. */
  sizeLarge: {
    paddingBottom: theme.spacing(1.125),
    paddingTop   : theme.spacing(1.125)
  },

  /* Styles applied to the root element if `size="small"`. */
  sizeSmall: {
    paddingBottom: theme.spacing(0.75),
    paddingTop   : theme.spacing(0.75)
  },

  /* Styles applied to the startIcon element if supplied. */
  startIcon: {
    '&$iconSizeSmall': {
      marginLeft: -2
    },
    display    : 'inherit',
    marginLeft : -4,
    marginRight: 8
  },

  /* Styles applied to the root element if `variant="text"`. */
  text: {
    '&:hover': {
      backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity)
    },
    border: '1px solid transparent'
  },

  /* Styles applied to the root element if `variant="text"` and `color="error"`. */
  textError: {
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity)
    },
    color: theme.palette.error.main
  },

  /* Styles applied to the root element if `variant="text"` and `color="krowdy"`. */
  textKrowdy: {
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: fade(theme.palette.krowdy.main, theme.palette.action.hoverOpacity)
    },
    color: theme.palette.krowdy.main
  },

  /* Styles applied to the root element if `variant="text"` and `color="primary"`. */
  textPrimary: {
    '&:hover': {
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity)
    },
    color: theme.palette.primary.main
  },

  /* Styles applied to the root element if `variant="text"` and `color="secondary"`. */
  textSecondary: {
    '&:hover': {
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },
      backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity)
    },
    color: theme.palette.secondary.main
  },

  /* Styles applied to the root element if `size="large"` and `variant="text"`. */
  textSizeLarge: {
    fontSize: theme.typography.pxToRem(14)
  },
  /* Styles applied to the root element if `size="small"` and `variant="text"`. */
  textSizeSmall: {
    fontSize: theme.typography.pxToRem(12)
  },
  wrapper: {
    display : 'initial',
    position: 'relative'
  }
})

const Button = React.forwardRef(function Button(props, ref) {
  const {
    children,
    classes,
    className,
    color = 'default',
    component = 'button',
    dashed = false,
    disabled = false,
    disableElevation = false,
    endIcon: endIconProp,
    focusVisibleClassName,
    fullWidth = false,
    px = 4,
    size = 'large',
    startIcon: startIconProp,
    type = 'button',
    variant = 'text',
    loading,
    ...other
  } = props

  const makeStylesClasses = useStyles({ px })

  const startIcon = startIconProp && <span className={clsx(classes.startIcon, classes[`iconSize${capitalize(size)}`])}>{startIconProp}</span>

  const endIcon = endIconProp && <span className={clsx(classes.endIcon, classes[`iconSize${capitalize(size)}`])}>{endIconProp}</span>

  const button = (
    <ButtonBase
      className={clsx(
        classes.root,
        classes[variant],
        {
          [classes[`${variant}${capitalize(color)}`]]   : color !== 'default' && color !== 'inherit',
          [classes[`${variant}Size${capitalize(size)}`]]: size !== 'medium',
          [classes[`size${capitalize(size)}`]]          : size !== 'medium',
          [classes.disableElevation]                    : disableElevation,
          [classes.disabled]                            : disabled,
          [classes.fullWidth]                           : fullWidth,
          [classes.colorInherit]                        : color === 'inherit',
          [classes.dashed]                              : dashed
        },
        className,
        makeStylesClasses.px
      )}
      component={component}
      disabled={loading || disabled}
      focusVisibleClassName={clsx(classes.focusVisible, focusVisibleClassName)}
      ref={ref}
      type={type}
      {...other}>
      {/*
       * The inner <span> is required to vertically align the children.
       * Browsers don't support `display: flex` on a <button> element.
       * https://github.com/philipwalton/flexbugs/blob/master/README.md#flexbug-9
       * TODO v5: evaluate if still required for the supported browsers.
       */}
      <span className={clsx({ [classes.label]: size === 'small', [makeStylesClasses.label]: size !== 'small' })}>
        {startIcon}
        {children}
        {endIcon}
      </span>
    </ButtonBase>
  )

  if(loading)
    return (
      <div className={clsx(classes.wrapper, { [classes.fullWidth]: fullWidth })}>
        {button}
        {loading && <CircularProgress className={classes.buttonProgress} color='inherit' size={20} />}
      </div>
    )

  return button
})

Button.propTypes = {
  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * The content of the button.
   */
  children        : PropTypes.node,
  /**
   * @ignore
   */
  className       : PropTypes.string,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes         : PropTypes.object,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color           : PropTypes.oneOf([ 'default', 'inherit', 'primary', 'secondary', 'krowdy', 'error' ]),
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component       : PropTypes.elementType,
  /**
   * If `true`, no elevation is used.
   */
  disableElevation: PropTypes.bool,
  /**
   * If `true`, the  keyboard focus ripple will be disabled.
   */

  /**
   * If `true`, the ripple effect will be disabled.
   *
   * ⚠️ Without a ripple there is no styling for :focus-visible by default. Be sure
   * to highlight the element by applying separate styles with the `focusVisibleClassName`.
   */
  disableRipple        : PropTypes.bool,
  /**
   * If `true`, the button will be disabled.
   */
  disabled             : PropTypes.bool,
  /**
   * Element placed after the children.
   */
  endIcon              : PropTypes.node,
  /**
   * @ignore
   */
  focusVisibleClassName: PropTypes.string,
  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth            : PropTypes.bool,
  /**
   * The URL to link to when the button is clicked.
   * If defined, an `a` element will be used as the root node.
   */
  href                 : PropTypes.string,
  loading              : PropTypes.bool,
  /**
   * Set both paddingLeft and paddingRight to the same value.
   */
  px                   : PropTypes.number,
  /**
   * The size of the button.
   * `small` is equivalent to the dense button styling.
   */
  size                 : PropTypes.oneOf([ 'large', 'medium', 'small' ]),
  /**
   * Element placed before the children.
   */
  startIcon            : PropTypes.node,
  /**
   * @ignore
   */
  type                 : PropTypes.oneOfType([ PropTypes.oneOf([ 'button', 'reset', 'submit' ]), PropTypes.string ]),
  /**
   * The variant to use.
   */
  variant              : PropTypes.oneOf([ 'contained', 'outlined', 'text', 'dashed' ])
}

const useStyles = makeStyles(
  (theme) => ({
    label: {
      ...theme.typography.body2,
      alignItems    : 'inherit',
      display       : 'inherit',
      justifyContent: 'inherit',
      width         : '100%'
    },
    px: {
      paddingLeft : ({ px }) => theme.spacing(px),
      paddingRight: ({ px }) => theme.spacing(px)
    }
  }),
  { name: 'Button-MakeStyles' }
)

export default withStyles(styles, { name: 'MuiButton' })(Button)
