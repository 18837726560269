export default {
  100 : '#c8e6c9',
  200 : '#a5d6a7',
  300 : '#81c784',
  400 : '#66bb6a',
  50  : '#e8f5e9',
  500 : '#4caf50',
  600 : '#43a047',
  700 : '#388e3c',
  800 : '#2e7d32',
  900 : '#1b5e20',
  A100: '#b9f6ca',
  A200: '#69f0ae',
  A400: '#00e676',
  A700: '#00c853'
}
