export default {
  1 : '#FFBB37',
  10: '#BFBFBF',
  2 : '#7FD26B',
  3 : '#F06C74',
  4 : '#9C82BD',
  5 : '#61A4D5',
  6 : '#FF7D37',
  7 : '#009C9C',
  8 : '#BC916A',
  9 : '#EE96DF'
}
